<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You Best</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">App Development</a></h3>
                    <p>Transform your ideas into innovative and user-friendly mobile applications with our expert app
                        development services. Our skilled developers combine cutting-edge technologies with intuitive
                        design to deliver seamless experiences across iOS, Android, and hybrid platforms, ensuring your
                        app stands out in a crowded marketplace.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3>
                    <p>Engage and expand your audience across various social media platforms with our targeted social
                        media marketing solutions. From creating compelling content to managing ad campaigns and
                        analyzing performance metrics, we help you build meaningful connections and drive conversions.
                    </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Content Marketing</a></h3>
                    <p>Capture your audience's attention and build brand authority through compelling content marketing
                        strategies. From blog posts and articles to videos and infographics, we create valuable and
                        shareable content that resonates with your target audience, driving traffic, engagement, and
                        conversions.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">SEO Optimization</a></h3>
                    <p>Elevate your online visibility and rankings with our comprehensive SEO optimization strategies.
                        We tailor our approach to suit your unique needs, conducting thorough keyword research,
                        optimizing on-page elements, and building quality backlinks to ensure long-term success in
                        search engine results.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Web Development</a></h3>
                    <p> Build a professional and responsive online presence that reflects your brand identity with our
                        customized web development solutions. From intuitive UX/UI design to robust backend development,
                        we craft websites that not only captivate visitors but also drive business growth and foster
                        customer loyalty.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">PPC Advertising</a></h3>
                    <p>Maximize your online visibility and drive targeted traffic to your website with our strategic PPC
                        advertising campaigns. Whether you're aiming to increase sales, generate leads, or boost brand
                        awareness, our certified PPC experts leverage data-driven insights and continuous optimization
                        to deliver measurable results and maximize your ROI</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/services" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services" class="page-numbers">2</a>
                    <a routerLink="/services" class="page-numbers">3</a>
                    <a routerLink="/services" class="page-numbers">4</a>
                    <a routerLink="/services" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">

            <div class="brand-slider owl-carousel owl-theme">
                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/pngegg-2.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/Park-view.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/logo-1-1.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/EMPEROR-Logo-Final-Transparent-BG.png"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/Sanjrani-Oxen-Logo.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/MB-enterprise-logo.png"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/physics.png.webp"
                            alt="Images"></a>
                </div>
            </div>

        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
