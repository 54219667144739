<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">About Us</span>
                        <h2>Grow Your Business With Our Agency</h2>
                    </div>
                    <h3>We're dedicated to propelling your business forward. From startups to enterprises, our tailored
                        solutions drive growth, boost visibility, and maximize ROI. With our experienced team, expect
                        innovation, creativity, and tangible results.</h3>
                    <p>As your trusted partner, we're committed to understanding your unique needs. Through
                        collaboration and strategic execution, we deliver measurable outcomes that elevate your brand.
                        Experience transparent communication, personalized service, and success-driven strategies with
                        us.</p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>High standards of professionalism, integrity. Establishment of close working
                                    relationships.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>A different way of thinking, both inside and outside the box. We do not have to be
                                    the best.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>We do not have to be the best, we do have to give our best Ability to learn.
                                    Multicultural competence.</p>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="video-btn popup-btn"><i
                                class='flaticon-forward'></i><span>Play Video </span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10 Years</h3>
                    <p>GLORIOUS YEARS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>70+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>25+</h3>
                    <p>SENIOR SCIENTIST</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>Our Journey’s Path</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Our Mission</h3>
                    <p>Empowering businesses for success.
                    </p>
                    <ul>
                        <li><i class='bx bx-check'></i> Creating Results.</li>
                        <li><i class='bx bx-check'></i> Good Thinking</li>
                        <li><i class='bx bx-check'></i> Expect More</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Who We Are</h3>
                    <p>A dedicated team passionate about your growth.
                    </p>
                    <ul>
                        <li><i class='bx bx-check'></i> We Have You Covered</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                        <li><i class='bx bx-check'></i> We Turn Heads</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Our History</h3>
                    <p>Evolving alongside our clients since day one.
                    </p>
                    <ul>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                        <li><i class='bx bx-check'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Frame the Problem <span>1</span></h3>
                            <p>Clearly define objectives and challenges to tackle. </p>
                        </li>
                        <li class="text-end">
                            <h3>Collect the Raw Data <span>2</span></h3>
                            <p>Gather relevant information from various sources. </p>
                        </li>
                        <li class="text-end">
                            <h3>Process the Data <span>3</span></h3>
                            <p>Organize and clean data for analysis. </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Explore the Data</h3>
                            <p>Dive deep into insights to uncover patterns. </p>
                        </li>
                        <li>
                            <h3><span>5</span>Perform Analysis</h3>
                            <p>Extract meaningful conclusions from the data. </p>
                        </li>
                        <li>
                            <h3><span>6</span>Communicate Results</h3>
                            <p>Present findings effectively to stakeholders and clients. </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our History</span>
            <h2>The Facts About Our Company</h2>
        </div>

        <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2010</h3>
                        <span>February 20th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2012</h3>
                        <span>January 14th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2016</h3>
                        <span>March 25th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2018</h3>
                        <span>December 10th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span>February 01th</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Great Success of the Year</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip comodo consequa dolor consectetur</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation </p>
                    <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->



<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-slider owl-carousel owl-theme">
                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/pngegg-2.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/Park-view.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/logo-1-1.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/EMPEROR-Logo-Final-Transparent-BG.png"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/Sanjrani-Oxen-Logo.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/MB-enterprise-logo.png"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/physics.png.webp"
                            alt="Images"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
