<app-navbar-style-one></app-navbar-style-one>


<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/service-details.jpg" alt="Images">
                        <h2>Content Marketing</h2>
                        <p>In today's digital landscape, content is king. Our comprehensive content marketing services
                            are designed to elevate your brand's online presence, engage your target audience, and drive
                            measurable results. Through strategic planning, creative storytelling, and data-driven
                            insights, we help you deliver the right message to the right audience at the right time,
                            maximizing your impact and fostering long-term relationships.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Real-time Keyword Data</h3>
                                <p>Gain a competitive edge with our real-time keyword data analysis. We provide
                                    up-to-the-minute insights into keyword trends, search volumes, and user behavior,
                                    allowing you to stay agile and responsive in your content strategy. By harnessing
                                    the power of real-time data, you can identify emerging opportunities, capitalize on
                                    trending topics, and ensure your content remains relevant and impactful in today's
                                    dynamic digital landscape.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Scalable Keyword Growth</h3>
                                <p>Fuel your content strategy with scalable keyword growth strategies. Our approach
                                    focuses on expanding your keyword portfolio strategically, targeting new
                                    opportunities and audience segments while maximizing your reach and impact. Whether
                                    you're looking to launch new campaigns, enter new markets, or optimize existing
                                    content, our scalable keyword growth solutions are designed to support your
                                    long-term goals and drive sustainable organic traffic growth.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>ROI Obsessed Keywords</h3>
                                <p>Unlock the full potential of your content investment with our ROI obsessed keyword
                                    optimization strategies. We go beyond search volume and competition metrics to
                                    identify keywords that drive tangible business results. By aligning your content
                                    with high-intent keywords that are proven to convert, we help you maximize your ROI
                                    and achieve measurable outcomes, from increased sales and leads to improved brand
                                    visibility and customer engagement.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Total Keyword Growth Visibility</h3>
                                <p>Gain comprehensive insights into your keyword performance and trajectory with our
                                    total keyword growth visibility solutions. Our advanced reporting tools provide a
                                    complete overview of your keyword landscape, tracking changes in rankings, search
                                    volume, and competition over time. With actionable insights at your fingertips, you
                                    can monitor progress, identify areas for improvement, and optimize your content
                                    strategy for maximum impact and success.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="content-widget-text">
                                    <h2>The Entire Solution For Content Marketer</h2>
                                    <p>Embark on a journey of content excellence with our holistic content marketing
                                        solutions. </p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> The Field of Data Science</li>
                                        <li><i class='bx bx-check'></i> SEO is Uniquely Built Around </li>
                                        <li><i class='bx bx-check'></i> Google’s search algorithm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>From ideation to execution, we provide the entire spectrum of services to support content
                        marketers at every stage of their journey. Whether you're looking to amplify your brand's story,
                        drive lead generation, or nurture customer relationships, our team of experts is here to help.
                        With a deep understanding of your audience and industry, we craft compelling content that
                        resonates with your target market, driving engagement, loyalty, and conversions. Partner with us
                        to unlock the full potential of your content strategy and achieve sustainable growth in today's
                        competitive landscape.
                    </p>

                    <!-- <div class="services-widget-list">
                        <ul>
                            <li class="active">
                                <span>75%</span>
                                <div class="content list1">
                                    <h3>SEO & Marketing</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Keywords Results</h3>
                                </div>
                                <span>66%</span>
                            </li>
                            <li>
                                <span>43%</span>
                                <div class="content list3">
                                    <h3>Google Analytics</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Off Page SEO</h3>
                                </div>
                                <span>15%</span>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="widget-category">
                        <h3>Other Services</h3>

                        <ul>
                            <li><a routerLink="/services-details">AI & ML Development</a></li>
                            <li><a routerLink="/services-details">Data Visualization</a></li>
                            <li><a routerLink="/services-details">Data Science</a></li>
                            <li><a routerLink="/services-details">Content Marketing</a></li>
                            <li><a routerLink="/services-details">SEO & Internet</a></li>
                        </ul>
                    </div>

                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:+564325677896">+56432 567 7896</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:hello&#64;ribo.com">hello&#64;ribo.com</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Location:</h3>
                                    <span>112/7 New York, USA</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="services-widget-two">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a routerLink="/services-details">PDF Download</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>02:</h3>
                                    <span><a routerLink="/services-details">Services Details.txt</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-slider owl-carousel owl-theme">
                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/pngegg-2.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/Park-view.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/logo-1-1.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/EMPEROR-Logo-Final-Transparent-BG.png"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/Sanjrani-Oxen-Logo.png.webp"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/MB-enterprise-logo.png"
                            alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img
                            src="https://stannumdigital.com/wp-content/uploads/2023/08/physics.png.webp"
                            alt="Images"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
